.animated-heart {
    animation: goingup-heart 10s linear, pulse 2s ease;
    animation-iteration-count: infinite;
}

@keyframes goingup-heart {
    to {
        top: -100px;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.7);
        opacity: 0.7;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.7);
        opacity: 0.7;
    }
}