/* css reset */

html {
    box-sizing: border-box;
    font-size: 16px;
    overflow: hidden;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
    overflow: hidden;
}

ol,
ul {
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}


/* css reset end */

.page {
    width: 100vw;
    height: 100vh;
    position: relative;
}

.frame {
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    margin: 5%;
    position: fixed;
    right: 0;
    top: 0;
}

.progress-container {
    bottom: 0;
    position: absolute;
    width: 100%;
}

.weeks-container {
    color: white;
    opacity: .7;
    font-size: 15vw;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 30%;
}

.weeks-container .days {
    font-size: 5vw;
}

.programmer-container {
    bottom: 2.6%;
    position: absolute;
    right: 12%;
    width: 30%;
}

.dog-container {
    position: absolute;
    bottom: 3%;
    width: 8%;
    left: 46%;
}

.pregnancy-container {
    left: 5%;
    position: absolute;
    width: 35%;
    top: 8%;
}

.stork-container {
    position: absolute;
    right: 30%;
    top: 20%;
    width: 10%;
}